<template><Icone/></template>

<script>
export default {
  name: 'IconeSablier',
  components: {
    Icone: () => import('./inline.IconeSablier.svg'),
  },
}
</script>
